.login {
}

.googleButton {
  width: 294px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  align-items: center;
  background: #ffffffab;
  column-gap: 15px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: 0.1s;
  -webkit-box-shadow: 0px 1px 4px 0px #0000003a;
  box-shadow: 0px 1px 4px 0px #0000003a;
  &:hover {
    background-color: #fff;
  }
  img {
    width: 20px;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
  }
  div {
    margin-right: auto;
  }
}
