.pets {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  .pet {
    width: 300px;
    height: 180px;

    border-radius: 6px;
    transition: 0.1s;
    box-sizing: border-box;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black);
    font-weight: bold;
    font-size: 12px;
    border: 3px solid transparent;
    background: #fff;
    .name {
      font-size: 20px;
    }
    &.Male {
      border-color: rgba(150, 240, 255, 0.4);
      &:hover {
        background: rgba(150, 240, 255, 0.05);
      }
    }
    &.Female {
      border-color: rgba(255, 150, 239, 0.4);
      &:hover {
        background: rgba(255, 150, 239, 0.05);
      }
    }
    &.addPet {
      border: 3px dashed #0000002f;
      font-size: 20px;
      font-weight: 500;
      color: #00000088;
      &:hover {
        background-color: rgba(143, 143, 143, 0.178);
      }
    }

    .info {
      text-align: center;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
  }
}
.content {
  display: grid;
  grid-template-columns: 3fr 1.2fr;
  column-gap: 16px;
  align-items: start;
  height: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin-bottom: 0;
  }
}
.view {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: var(--border);
  padding: 15px 20px 20px;
  .headerButtons {
    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .line {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    padding: 10px;
    border: var(--border);
    border-radius: 4px;
    img {
      height: 21px;
      display: block;
    }
  }
}

.pillsData {
  border: var(--border);
  border-radius: 10px;
  background-color: #fff;
  padding: 15px 20px;
  min-height: 507px;
  max-height: calc(100% - 28px);
  overflow: auto;
  .header {
    button {
      font-size: 18px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

.pills {
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.pillItem {
  padding: 5px;
  border: var(--border);
  border-radius: 4px;

  position: relative;
  &:hover {
    .deleteButton {
      opacity: 1;
    }
    .daysAgo {
      opacity: 0;
    }
  }
  .deleteButton {
    position: absolute;
    right: 5px;
    top: 5px;
    transition: 0.2s;
    opacity: 0;
    button {
      font-size: 11px;
      padding: 2px 4px;
    }
  }
  .dateData {
    display: flex;
    justify-content: space-between;
  }
  .date {
    font-size: 13px;
    font-weight: bold;
  }
  .daysAgo {
    font-size: 12px;
    font-weight: 500;
    transition: 0.1s;
    pointer-events: none;
  }
  .name {
    font-size: 11px;
    margin-top: 5px;
    opacity: 0.7;
  }
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  margin-top: 10px;

  .tag {
    background: rgb(180, 23, 23);
    padding: 2px 4px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 16px;
    }
    &.active {
      background: rgb(23, 180, 23);
    }
  }
}

.vaccinationDiff {
  font-size: 14px;
  margin-left: 10px;
}
