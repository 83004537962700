.tag {
  background: #333;
  width: 80px;
  text-align: center;
  padding: 2px 10px;
  color: #fff;
  border-radius: 4px;
  &.veryLow {
    background: rgb(30, 190, 8);
  }
  &.low {
    background: rgb(250, 225, 0);
  }
  &.medium {
    background: rgb(255, 145, 0);
  }
  &.critical {
    background: #ff5151;
  }
}
