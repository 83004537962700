.form {
  margin-top: 30px;
  select {
    width: 100%;
  }
}
.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-top: 18px;

  column-gap: 18px;
}

.buttons {
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18px;
}
