@import "../../../../styles/mixins.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  @include item();
  grid-template-columns: 1fr 180px 100px 35px;
  &:first-child {
    z-index: 1000;
  }
}

.buttons {
  button:first-child {
    margin-right: 10px;
  }
}

.costsByLabel {
  height: calc(100% - 48px);
  .table {
    max-height: calc(100% - 47px);
  }
  input {
    border: none;
    padding: 0;
    line-height: 21px;
    height: 21px;
    font-size: 16px;
  }
  .name {
    cursor: pointer;
  }
  .edit {
    display: grid;
    grid-template-columns: 1fr 100px 100px;
    column-gap: 20px;
    align-items: center;
  }
}
