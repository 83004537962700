.modal {
  h3 {
    text-align: center;
    margin: 0;
  }
}

.form {
  margin-top: 30px;
}

input {
  width: 100%;
  box-sizing: border-box;
}

.fields {
  display: grid;
  margin-top: 18px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  column-gap: 13px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  & > button {
    width: 47%;
  }
}
