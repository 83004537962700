@import "./styles/mixins.scss";

.block {
  @include container;
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  column-gap: 16px;
}

.modal {
  text-align: center;
  img {
    width: 60px;
    border-radius: 50%;
    margin-top: 15px;
  }
  .text {
    margin-top: 8px;
    font-weight: 500;
    font-size: 18px;
  }
  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 15px;
  }
}
