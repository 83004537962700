@import "../../../../styles/mixins.scss";

.summary {
  border: var(--border);
  border-radius: 10px;
  background-color: #fff;
  padding: 15px 0px 0px 20px;
  overflow: hidden;
  height: calc(100% - 69px);
  .item {
    @include item();
    grid-template-columns: 1fr 0.6fr 89px 20px;
    padding-right: 45px;
    padding: 12px 25px;
    max-width: 100%;
    overflow: hidden;
    button {
      opacity: 0;
      padding: 1px 9px;
    }
    &.disabled {
      pointer-events: none;
    }
    &:not(:first-child):not(:last-child) {
      cursor: pointer;
      transition: 0.1s;
      background-color: #fff;

      &:hover {
        transform: scale(1.01);
        button {
          opacity: 0.7;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &:last-child {
      cursor: default;
      border-bottom: 3px solid #aaa;
      font-weight: 500;
      background: #aaaaaa10 !important;
    }
  }
}
.content {
  display: grid;
  grid-template-columns: 330px 1fr;
  column-gap: 20px;
  height: calc(100% - 50px);
  overflow: auto;
}

.pieChart {
  display: grid;
  row-gap: 20px;
  align-content: start;
}

.table {
  border-radius: 0;
  border-top: none;
  border-bottom: none;
  border-right: none;
  height: 100%;
  align-items: start;
  align-content: start;
  row-gap: 0;
  max-width: 100%;
  overflow-x: hidden;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 0;
  }
}

.color {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: red;
  border-radius: 50%;
}
