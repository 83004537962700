.members {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.add {
  width: 300px;
  height: 180px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  border: 3px dashed #0000002f;
  color: #00000088;
  cursor: pointer;
  transition: 0.1s;
  box-sizing: border-box;
  &:hover {
    background-color: rgba(143, 143, 143, 0.178);
  }
}
