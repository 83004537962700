@import "../../../../styles/mixins.scss";

.costs {
  height: calc(100% - 48px);
  .table {
    max-height: calc(100% - 47px);
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2 {
      margin-bottom: 0;
    }
    div {
      background: #fff;
      padding: 2px 10px;
      border-radius: 4px;
    }
  }
  .item {
    @include item();
    grid-template-columns: 1fr repeat(3, 150px) 53px;
    &:not(:last-child):not(:first-child) {
      cursor: pointer;
      transition: 0.1s;
      &:hover {
        opacity: 0.6;
      }
    }
    &:last-child {
      font-weight: 500;
      border-top: 2px solid var(--black);
    }
    button {
      height: 21px;
      padding: 0;
      width: 100%;
    }
  }
}

.payments {
  height: 100%;
  .table {
    height: calc(100% - 60px);
  }
  .item {
    @include item();
    grid-template-columns: 21px 0.7fr 140px 1fr 140px 140px;
    cursor: pointer;
  }
  img {
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
}

.form {
  display: grid;
  grid-template-columns: 200px 160px 1fr 140px 140px;
  align-items: center;
  padding: 8px 25px;
  column-gap: 10px;
  input,
  select {
    border: none;
    padding: 0;
    line-height: 21px;
    height: 21px;
    font-size: 16px;
  }
  button {
    height: 21px;
    padding: 0;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  margin-top: 20px;
  button {
    width: 100%;
  }
}

.modal {
  display: grid;
  row-gap: 20px;
  select {
    margin: 10px 0;
    font-size: 16px;
    width: 100%;
  }
}

.comment {
  width: 100%;
}

.balancePerDay {
  display: flex;
  align-items: center;
  span {
    font-size: 13px;
    margin-left: 2px;
    letter-spacing: -0.5px;
    &.good {
      color: #2cd852;
    }
    &.bad {
      color: #ff4c4c;
    }
  }
}
