@mixin container {
  max-width: 1368px;
  margin: 0 auto;
  padding: 0 24px;
}

@mixin item {
  display: grid;
  padding: 8px 25px;
  justify-items: start;
  column-gap: 10px;
  align-items: center;
  &:nth-child(2n-1) {
    &:not(:first-child) {
      background: rgba(241, 245, 248, 0.6);
    }
  }
  &.done {
    opacity: 0.5;
    background-color: #eeeeee;
  }
  &:first-child {
    border-bottom: 1px solid #00000021;
    position: sticky;
    background-color: #fff;
    top: 0;
  }
  span.done {
    pointer-events: none;
    opacity: 0.5;
  }
}

@mixin tableButton {
  padding: 2px 4px;
  min-width: 30px;
  background: var(--black);
  color: #fff;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
  transition: 0.1s;
  height: 25px;
  &:not(:first-child) {
    margin-left: 10px;
  }
  &:hover {
    opacity: 0.7;
  }
}
