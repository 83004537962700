@import "../../../styles/mixins.scss";

.tabs {
  display: flex;
  flex-wrap: wrap;
}
.tab {
  line-height: 16px;
  font-weight: 500;
  padding: 5px 15px;
  transition: 0.1s;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  &:not(:first-child) {
    margin-left: 15px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.active {
    background: var(--black);
    color: #fff;
  }
}

.users {
  display: flex;
  column-gap: 2px;

  img {
    height: 30px;
    border-radius: 50%;
    border-style: outset;
    border: 2px solid transparent;
    cursor: pointer;
    &.active {
      border-color: rgb(29, 101, 255);
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 460px 1fr auto;
  align-items: center;
  margin-bottom: 20px;
  column-gap: 20px;
}

.selectedMonth {
  color: var(--black);
  padding: 5px 10px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  line-height: 16px;
  border: 1px solid var(--black);
  display: flex;
  align-items: center;

  img {
    height: 16px;
    display: block;
    margin-right: 5px;
  }
}

.buttons {
  display: flex;
  button {
    padding: 3px 9px;
  }
}
