.loader {
  position: relative;
  min-height: 280px;
  height: auto;
  width: 100%;
  background: #fff;
  border-radius: 8px;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img {
    margin-bottom: 10px;
  }
}
