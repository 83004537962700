.text {
  font-size: 16px;
  font-weight: 500;
}

.value {
  font-size: 20px;
  font-weight: 700;
}

.pieChartWrapper {
  height: 260px;
  width: 100%;
  h3 {
    margin-bottom: 0;
  }
}
