.table {
  display: grid;
  row-gap: 2px;
  grid-template-columns: 1fr;

  background: #fff;
  border-radius: 8px;
  overflow: auto;
  max-height: 100%;
  height: auto;
  border: var(--border);
  align-items: start;
}
