.button {
  border: 2px solid var(--black);
  padding: 5px 9px;
  border-radius: 4px;
  background: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: 0.1s;
  text-align: center;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    color: var(--black);
    &.secondary {
      color: #fff;
    }
  }
  &:hover {
    background: var(--black);
    color: #fff;
  }
  &.primary {
    background: #fff;
    color: var(--black);
    border-color: transparent;
    box-shadow: 0 1px 0 #dce5ef, 0 1px 4px rgba(9, 8, 61, 0.08);
    &:hover {
      box-shadow: 0 1px 0 #dce5ef, 0 1px 4px rgba(9, 8, 61, 0.2);
    }
  }
  &.secondary {
    background: var(--black);
    color: #fff;
    &:hover {
      opacity: 0.7;
    }
  }
  &.remove {
    background: none;
    border-color: transparent;
    opacity: 0.7;
    border: none;
    padding-top: none;
    &:hover {
      opacity: 1;
    }
  }
  img {
    height: 16px;
  }
}
