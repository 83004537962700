@import "../../styles/mixins.scss";

.header {
  background-color: #fff;
  box-shadow: 0 1px 0 #dce5ef, 0 1px 4px rgba(9, 8, 61, 0.08);
}

.container {
  @include container;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
}

.leftSide {
  display: flex;
  width: 260px;
  justify-content: space-between;
  align-items: center;
  .back {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    border: 1px solid var(--black);
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.1s;
    &:hover {
      opacity: 1;
    }
  }
}

.userData {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  position: relative;

  cursor: pointer;
  transition: 0.1s;
  border-radius: 6px;
  &:hover {
    box-shadow: 0 1px 0 #dce5ef, 0 1px 4px rgba(9, 8, 61, 0.08);
  }
  img {
    width: 35px;
    border-radius: 50%;
    margin-left: 6px;
  }
}

.popup {
  position: absolute;
  left: 0;
  opacity: 0;

  pointer-events: none;
  top: 40px;
  transition: 0.2s;
  &.active {
    top: 54px;
    opacity: 1;
    pointer-events: all;
  }
  button {
    width: 100%;
  }
}
