.card {
  background: #fff;
  padding: 20px 0;
  border-radius: 6px;
  position: relative;
  width: 300px;
  height: 180px;
  box-sizing: border-box;
}

.content {
  text-align: center;
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    border-radius: 50%;
    width: 60px;
    margin-bottom: 5px;
  }
}

.status {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  border: 1px solid rgb(255, 76, 76);
  padding: 2px 6px;
  border-radius: 4px;
  color: rgb(255, 76, 76);
  &.verified {
    color: rgb(44, 216, 82);
    border: 1px solid rgb(44, 216, 82);
  }
}

.role {
  font-size: 11px;
  line-height: 11px;

  text-transform: uppercase;
  padding: 2px 6px;
  border: 1px solid #333;
  width: fit-content;
  border-radius: 4px;
  margin: 4px auto 0;
}
