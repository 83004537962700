body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(241, 245, 248);

  --black: #1b1616;
  --border: 1px solid #d3dfeb;
  color: var(--black);
}

input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 20px;
  margin-top: 0px;
}

h3 {
  font-size: 16px;
  margin-top: 0;
}

h4 {
  font-size: 12px;
  margin: 0;
}
input,
select {
  outline: none;
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;
  font-size: 16px;
  padding: 6px 15px;
  background-color: transparent;
}

select {
  padding-right: 0;
}

* {
  box-sizing: border-box;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #8888889a;
    border-radius: 4px;
  }
}
